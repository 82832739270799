<template>
  <div class="plan-waitmake-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item>
          <i class="icon-home"></i>方案管理</Breadcrumb-item>
        <Breadcrumb-item>待调整</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <v-member-panel></v-member-panel>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="85">
          <Row>
              <Col span="6">
                <Form-item label="方案名称：">
                  <span class="plan-name">{{name}}</span>
                  <a :href="file" target="_blank" class="download">下载方案</a>
                </Form-item>
              </Col>
              <Col span="6">
                <template v-if="add_mark_detail !== '' ">
                  <Form :label-width="60" style="display:inline-block; width:300px;" >
                    <Form-item label="备注：">
                      <a style="color: #00cb84" @click="goSee()">查看</a>
                    </Form-item>
                  </Form>
                </template>
              </Col>
          </Row>
          <Row>
            <Col span="24">
            <Form-item label="调整原因：">
              <span>{{adjust_reason}}</span>
            </Form-item>
            </Col>
          </Row>
        </Form>
        <Upload 
          ref="upload"
          :show-upload-list="false"
          :on-success="handleSuccess"
          :before-upload="handleBeforeUpload"
          :format="['doc','docx','pdf']"
          :on-format-error="handleFormatError"
          action="/api/plan/uploadfile"
          style="display:inline-block; width:300px;">
          <a href="javascript:;" class="btn-upload">上传方案资料</a>
        </Upload>
        <div class="upload-list" v-for="item in uploadList" :key="item.name">
          <template v-if="item.status === 'finished'">
            {{item.name}}
            <div class="upload-list-cover">
              <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
            </div>
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
          </template>
        </div>
        <div class="submit-box tac">
          <Button type="success" size="large" @click="okSubmit()">提交</Button>
        </div>
      </div>
      <p class="log-title">操作日志</p>
      <Table stripe :columns="columns" :data="logList" @on-selection-change="selectOne"></Table>
    </div>
    <!--查看方案备注modal-->
    <Modal v-model="addMarkmodal" title="查看备注">
      <div class="slotbody">
        <div class="feed-txt break-word">{{add_mark_detail}}</div>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okCancel">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import vMemberPanel from 'pages/member/common/member-fixed-panel';
import planService from '@/services/planService';
export default {
	components: {
		'v-title': vTitle,
		'v-member-panel': vMemberPanel,
	},
	data() {
		return {
			submitForm: {
				name: '',
				file_id: '',
				plan_id: '',
			},
			name: '',
			file: '',
			adjust_reason: '',
			uploadList: [],
			shmodal: false,
			add_mark_detail: '',
			addMarkmodal: false,
			columns: [
				{ title: '操作人', key: 'operater', align: 'center' },
				{ title: '操作', key: 'info', align: 'center' },
				{ title: '说明', key: 'remark', align: 'center' },
				{ title: '时间', key: 'day', align: 'center' },
			],
		};
	},
	computed: {
		planId() {
			return this.$route.params.plan_id;
		},
	},
	created() {
		var param = { id: this.planId };
		planService.getDetail(param).then((data) => {
			this.submitForm.plan_id = data.id;
			this.submitForm.name = data.name;
			this.name = data.name;
			this.file = data.file;
			this.adjust_reason = data.adjust_reason;
			this.logList = JSON.parse(data.history);
			this.add_mark_detail = data.add_mark;
		});
		var typeid = { type_id: 2 };
		planService.getTeamList(typeid).then((data) => {
			this.checkTeamList = data.team;
			this.check_team_member = data.team_member;
		});
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		handleSuccess(res, file, fileList) {
			this.submitForm.file_id = res.file_id;
			this.uploadList = fileList;
		},
		handleRemove(file) {
			// 从 upload 实例删除数据
			const fileList = this.$refs.upload.fileList;
			this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
			this.submitForm.file_id = '';
		},
		handleBeforeUpload() {
			const check = this.uploadList.length < 1;
			if (!check) {
				this.$Message.warning('只能上传一个方案');
			}
			return check;
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 doc、docx 或 pdf 格式的文件。',
				2,
			);
		},
		validateFormData() {
			if (!this.submitForm.file_id) {
				this.$Message.warning('请上传方案资料');
				return false;
			}
			return true;
		},
		okSubmit() {
			if (this.validateFormData()) {
				planService.submitAdjust(this.submitForm).then(() => {
					this.$router.push({ path: '/base/plan' });
				});
			}
		},
		cancel() {
			this.shmodal = false;
		},
		goSee() {
			this.addMarkmodal = true;
		},
		okCancel() {
			this.addMarkmodal = false;
		},
	},
};
</script>

<style lang="css">
.plan-waitmake-container .ivu-form-item {
  margin-bottom: 14px;
}
.plan-waitmake-container .submit-box {
  margin-top: 30px;
}
.plan-waitmake-container .ivu-btn-success {
  padding: 11px 48px;
}
.slotbody {
  padding-bottom: 12px;
}
.slotbody p {
  padding: 12px 0;
}
.break-word {
  word-break: break-all;
}
.feed-txt {
  text-indent: 2em;
  line-height: 20px;
}
</style>
